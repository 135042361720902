import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import "@/utils/element.js";
import "element-ui/lib/theme-chalk/index.css";
import MetaInfo from "vue-meta-info";
import "default-passive-events";
Vue.use(MetaInfo);
Vue.config.productionTip = false;
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

new Vue({
  render: (h) => h(App),
  router,
  store,
  mounted: () => document.dispatchEvent(new Event("render-event")),
}).$mount("#app");
