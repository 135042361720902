//引入Vuex -----相当于咱们最大的仓库
import Vuex from "vuex";
//引入Vue
import Vue from "vue";
//使用插件
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    latestDevelopmentsArr: [],
    activityForecastArr: [],
    listType:1,
  },
  mutations: {
    saveLatestDevelopmentsArr(state, arr) {
      state.latestDevelopmentsArr = arr;
    },
    saveActivityForecastArr(state, arr) {
      state.activityForecastArr = arr;
    },
    saveListType(state,type){
      state.listType = type;
    }
  },
});
