// 配置路由
import Vue from "vue";
import VueRouter from "vue-router";
// 使用插件
Vue.use(VueRouter);
// 引入路由组件

// 先把VueRouter原型对象的push,先保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

// 重写push | replace
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //call | apply 区别
    //相同点,都可以调用函数一猜，都可以篡改函数的上下文一次
    //不同点,call与apply传递参数，call传递参数用逗号隔开，apply方法执行，传递数组
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    //call | apply 区别
    //相同点,都可以调用函数一猜，都可以篡改函数的上下文一次
    //不同点,call与apply传递参数，call传递参数用逗号隔开，apply方法执行，传递数组
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// 配置路由
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path:'/businessCooperation',
      component:()=>import('@/views/BusinessCooperation')
    },
    {
      path:'/business',
      component:()=>import('@/views/Business')
    },
    {
      path:'/',
      component:()=>import('@/views/Home')
    },
    // 重定向
    {
      path: "*",
      redirect: "/"
    },
  ],
});
// 全局后置导航
router.afterEach(() => {
  document.documentElement.scrollTop = 0;
  window.scroll(0,0);
});
export default router;
