<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html{
  overflow-y:auto;
}
#app {
  /* height: 100vh; */
  /* overflow-y: auto; */
  background: #FCFCFC;
}
</style>
